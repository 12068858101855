import React, { useState } from 'react';
import { Link, navigate, PageProps } from 'gatsby';
import Header from '../../components/header/header';
import { Subheader } from '../../components/subheader/subheader';
import { TranslationService } from '../../services/translation.service';
import * as styles from './signup.module.scss';
import { TranslationProvider } from '../../contexts/translation.context';
import { useForm } from '@/src/hooks/form.hook';
import { createFormField, emailValidator, FormConfig, passwordMatchValidator, requiredValidator, yearValidator } from '@/src/models/form.model';
import { FormError } from '@/src/components/form-error/form-error';
import { LargeButton } from '@/src/components/button/button';
import Footer from '@/src/components/footer/footer';
import Spinner from '@/src/components/spinner/spinner';
import { AuthService } from '@/src/services/auth.service';
import { countries } from '@/src/models/countries.model';
import { Helmet } from 'react-helmet';

const year = (new Date()).getFullYear();
const years = Array.from(new Array(100), (val, index) => -(index - year));

const signupForm: FormConfig = {
  firstName: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="firstName"
            name="firstName"
            placeholder={translation.translate('authentication.first_name')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  lastName: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="lastName"
            name="lastName"
            placeholder={translation.translate('authentication.last_name')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      },
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  address: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="address"
            name="address"
            placeholder={translation.translate('authentication.address')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  zip: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="zip"
            name="zip"
            placeholder={translation.translate('authentication.zip')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  city: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="city"
            name="city"
            placeholder={translation.translate('authentication.city')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  birthYear: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <select
            key={key}
            name="birthYear"
            id="birthYear"
            value={value ? value : ''}
            onChange={event => onInputChange({ target: event.target } as any)}>
            {years.map(year => (
              <option key={year} value={year}>{year}</option>
            ))}
          </select>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: []
  },
  country: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <select
            key={key}
            name="country"
            id="country"
            value={value ? value : ''}
            onChange={event => onInputChange({ target: event.target } as any)}>
            {Object.keys(countries).map(countryKey => (
              <option key={countryKey} value={countryKey}>{countries[countryKey]}</option>
            ))}
          </select>
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    defaultValue: 'AX',
    validationRules: []
  },
  email: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="email"
            name="email"
            placeholder={translation.translate('authentication.email')}
            type="text"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      emailValidator()
    ]
  },
  password: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="password"
            name="password"
            placeholder={translation.translate('authentication.password')}
            type="password"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator()
    ]
  },
  repeatPassword: {
    ...createFormField(
      (onInputChange, translation, value, isValid, error, key) => {
        return <>
          <input
            key={key}
            id="repeatPassword"
            name="repeatPassword"
            placeholder={translation.translate('authentication.repeat_password')}
            type="password"
            value={value ? value : ''}
            onChange={onInputChange} />
          <FormError valid={isValid} message={error}></FormError>
        </>;
      }
    ),
    validationRules: [
      requiredValidator(),
      passwordMatchValidator('password')
    ]
  }
};

export default function SignupPage({ pageContext }: PageProps) {
  const [translation] = useState(new TranslationService(pageContext));
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState('');
  const { renderInput, isFormValid, touchForm, form } = useForm(translation, signupForm);

  const tabs = [
    {
      name: translation.translate('authentication.new_user'),
      href: `/${translation.lang}/signup`
    },
    {
      name: translation.translate('authentication.log_in'),
      href: `/${translation.lang}/login`
    }
  ];

  const signup = async (event: React.FormEvent) => {
    event.preventDefault();
    touchForm();
    if(!isFormValid()) {
      return;
    }
    setFormError(false);
    setLoading(true);
    const result = await new AuthService().createUser({
      firstName: form.firstName.value,
      lastName: form.lastName.value,
      email: form.email.value,
      country: form.country.value,
      birthYear: parseInt(form.birthYear.value),
      password: form.password.value,
      address: {
        street: form.address.value,
        city: form.city.value,
        zip: form.zip.value
      }
    });
    setLoading(false);

    // Track create account event
    (window as any).ga(
      'send',
      {
        hitType: 'event',
        eventCategory: 'TodoAlandSignup',
        eventAction: 'signup',
        country: form.country.value,
        birthYear: parseInt(form.birthYear.value),
      }
    );

    if(!result.success) {
      setFormError(true);
      switch(result.message) {
        case 'email-already-exists':
          setFormErrorMessage(translation.translate('authentication.email_already_exists'));
          break;
        case 'invalid-argument':
          setFormErrorMessage(translation.translate('authentication.invalid_argument'));
          break;
        case 'invalid-password':
          setFormErrorMessage(translation.translate('authentication.invalid_password'));
          break;
        default:
          setFormErrorMessage(translation.translate('authentication.unexpected_error'));
          break;
      }
    } else {
      navigate(`/${translation.lang}/login`);
    }
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate('helmet.signup_title')}</title>
        <meta name="description" content={translation.translate('helmet.signup_description')} />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>
      <Header></Header>

      <div className="scrollable no-navbar">
        <div className="content">
          <Subheader.Tabs activeHref={`/${translation.lang}/signup`} tabs={tabs}></Subheader.Tabs>

          <h1 className={styles.heading}>
            {translation.translate('authentication.create_account')}
          </h1>

          <div className="app__wrapper">
            <form className="app__form" onSubmit={signup}>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="firstName">{translation.translate('authentication.first_name')}<span className="required-label">*</span></label>
                  {renderInput('firstName')}
                </div>

                <div className="form__field">
                  <label htmlFor="lastName">{translation.translate('authentication.last_name')}<span className="required-label">*</span></label>
                  {renderInput('lastName')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="address">{translation.translate('authentication.address')}<span className="required-label">*</span></label>
                  {renderInput('address')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="zip">{translation.translate('authentication.zip')}<span className="required-label">*</span></label>
                  {renderInput('zip')}
                </div>
                <div className="form__field">
                  <label htmlFor="city">{translation.translate('authentication.city')}<span className="required-label">*</span></label>
                  {renderInput('city')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="country">{translation.translate('authentication.country')}<span className="required-label">*</span></label>
                  {renderInput('country')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="birthYear">{translation.translate('authentication.birth_year')}<span className="required-label">*</span></label>
                  {renderInput('birthYear')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="email">{translation.translate('authentication.email')}<span className="required-label">*</span></label>
                  {renderInput('email')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="password">{translation.translate('authentication.password')}<span className="required-label">*</span></label>
                  {renderInput('password')}
                </div>
              </div>

              <div className="form__group">
                <div className="form__field">
                  <label htmlFor="repeatPassword">{translation.translate('authentication.repeat_password')}<span className="required-label">*</span></label>
                  {renderInput('repeatPassword')}
                </div>
              </div>

              {formError ?
                <div className={styles.errorMessage}>
                  {formErrorMessage}
                </div> : null}

              <div className="form__group form__group--submit">
                <LargeButton type="submit" disabled={loading}>
                  {loading ? <Spinner></Spinner> : null}
                  <span>{translation.translate('authentication.create_account')}</span>
                </LargeButton>
              </div>

              <p className={styles.loginLink}>
                {translation.translate('authentication.already_signed_up') + ' '}
                <Link to={`/${translation.lang}/login`}>Logga in</Link>
              </p>

            </form>
          </div>
        </div>

        <Footer></Footer>
      </div>
    </TranslationProvider>
  );
}
